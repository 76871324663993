import axiosInfoFeed from '../axiosConfig';
var functionsForAdminWidgets = {
	async getCustomersAutocomplete(textInput) {
		const response = await axiosInfoFeed.get(
			'api/v1/customer/' + textInput
		);
		this.response = response.data.data;
		return this.response;
	},
	async getContractCustomer(encryptedDif) {
		const response = await axiosInfoFeed.get(
			process.env.VUE_APP_API_CONTRATOS + '/index.php?r=' + encryptedDif
		);
		this.response = response.data.data;
		return this.response;
	},
	async getCustomersDetails(bussinessCode) {
		const response = await axiosInfoFeed.get(
			'api/v1/infoguia/' + bussinessCode + '/customers'
		);
		this.response = response.data.data;
		return this.response;
	},
	async postRequest(dataForPost, bussinessCode) {
		const response = await axiosInfoFeed.post(
			'/api/v1/customer/' + bussinessCode + '/manualrequest',
			dataForPost
		);
		this.response = response.data.data;
		return this.response;
	},
	async getPendingAuth(recordsPerPage, goToPage, filter) {
		let page = goToPage !== null ? '&page=' + goToPage : '';
		let search = filter !== null ? '&like_by=' + encodeURI(filter) : '';
		const response = await axiosInfoFeed.get(
			'api/v1/report/toauthorize?sort_by=sendDate&per_page=' +
				recordsPerPage +
				page +
				search
		);
		this.response = response.data.data;
		return this.response;
	},
	async postNewEmail(dataForPost, idCustomers) {
		const response = await axiosInfoFeed.patch(
			'api/v1/customers/' + idCustomers,
			dataForPost
		);
		this.response = response.data.data;
		return this.response;
	},
	async deleteRequest(idCustomers) {
		const response = await axiosInfoFeed.delete(
			'/api/v1/customers/' + idCustomers
		);
		this.response = response.data.data;
		return this.response;
	},
	async getHistoryDetails(idCustomers, recordsPerPage, goToPage, filter) {
		let page = goToPage !== null ? '&page=' + goToPage : '';
		let search = filter !== null ? '&like_by=' + encodeURI(filter) : '';
		const response = await axiosInfoFeed.get(
			'/api/v1/customers/' +
				idCustomers +
				'/systemlogs?per_page=' +
				recordsPerPage +
				page +
				search
		);
		this.response = response.data.data;
		return this.response;
	},
};
export default functionsForAdminWidgets;

<template>
	<ValidationObserver v-slot="{ invalid }">
		<b-modal
			v-model="triggerModal"
			centered
			no-close-on-backdrop
			size="lg"
			header-bg-variant="info"
			header-text-variant="light"
		>
			<CRow>
				<CCol>
					<span>
						<p>
							Para editar el e-mail del cliente por favor ingresa
							el nuevo e-mail en el siguiente campo y haz clic en
							el bot&oacute;n <b>"Cambiar Email"</b> para
							continuar.
						</p>
						<p>
							Si no deseas cambiar el e-mail haz clic en el
							bot&oacute;n <b>"Cancelar".</b>
						</p>
					</span>
				</CCol>
			</CRow>
			<CRow>
				<CCol sm="6">
					<ValidationProvider
						name="E-Mail"
						rules="required|email"
						v-slot="{ valid, errors }"
					>
						<CInput v-model="newEmail" :isValid="valid"
							><template #invalid-feedback>
								<span class="invalid-feedback">{{
									errors[0]
								}}</span>
							</template>
							<template #description>
								<span class="style-description"
									>Ingresa un E-Mail.</span
								>
							</template></CInput
						>
					</ValidationProvider>
				</CCol>
			</CRow>
			<template v-slot:modal-header>
				<h5 class="modal-title"><b>Cambiar E-Mail</b></h5>
				<CButtonClose @click="closeModal()" class="text-white" />
			</template>
			<template v-slot:modal-footer>
				<b-button
					@click="changeEmail()"
					:disabled="invalid"
					pill
					variant="success"
					class="mr-1 text-white"
				>
					Cambiar E-Mail
				</b-button>
				<b-button
					@click="closeModal()"
					pill
					variant="info"
					class="mr-1 text-white"
					>Cancelar
				</b-button>
			</template>
		</b-modal>
	</ValidationObserver>
</template>

<script>
	import '../../validations/validationsForm';
	export default {
		name: 'editEmail',
		props: {
			triggerModal: {
				type: Boolean,
				default: function() {
					return false;
				},
			},
			email: {
				type: String,
				default: function() {
					return '';
				},
			},
		},
		created() {},
		data() {
			return {
				showModal: this.triggerModal,
				newEmail: this.email,
			};
		},
		methods: {
			closeModal() {
				this.$emit('closeModal');
			},
			changeEmail() {
                this.$emit('editEmail', this.newEmail)
			},
		},
	};
</script>

<style></style>
